<template>
  <div class="wrapper">
    <div class="travel-guide">
      <loader v-if="LOADER" />

      <div class="travel-guide__container" v-else>
        <div class="card" v-for="(item, index) in currentDataSet" :key="index">
          <lazy-card :data="item" @click.native="goToObject(item)" />
        </div>

        <span class="notification" v-if="NOTIFICATION">
          {{ NOTIFICATION }}
        </span>
      </div>
    </div>

    <div class="tags">
      <swiper :options="{ slidesPerView: 'auto', touchRatio: 2, freeMode: true }">
        <swiper-slide v-for="(tag, index) in filterTypes" :key="index">
          <tag :title="tag.name" :active="tag.id === activeBadge.id" @click.native="filterByCategory(tag)" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LazyCard from '@/components/Parts/LazyCard';
import ObjectCard from "@/components/Parts/ObjectCard";
import Tag from "@/components/Parts/Tag";
import Loader from "@/components/Parts/Loader";

export default {
  name: "TravelGuide",

  components: {
    LazyCard,
    ObjectCard,
    Tag,
    Loader
  },

  computed: {
    ...mapGetters(["GET_PLACES", "GET_FILTERED_PLACES", "GET_PLACES_CATEGORIES", "LOADER", "NOTIFICATION"]),

    currentDataSet() {
      if (this.isFiltered) {
        return this.GET_FILTERED_PLACES;
      }
      return this.GET_PLACES;
    },

    filterTypes() {
      let arr = [
        {
          name: "Все",
          id: 0,
        },
      ]
      return arr.concat(this.GET_PLACES_CATEGORIES);
    },
  },

  data() {
    return {
      activeBadge: {
        name: "Все",
        id: 0,
      },

      isFiltered: false
    }
  },

  methods: {
    goToObject(object) {
      this.$store.dispatch("setSelectedObject", object);
      this.$router.push({ path: `/object/${object.id}` });
    },

    filterByCategory(tag) {
      if (this.activeBadge === tag) return;

      this.activeBadge = tag;

      if (tag.id === 0) {
        this.isFiltered = false;
      } else {
        const filteredPlaces = this.GET_PLACES.filter(item => item.category.id == tag.id)
        this.$store.dispatch('GET_FILTERED_PLACES_FROM_SERVER',
            { places: filteredPlaces }).finally(() => {
          this.isFiltered = true;
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.travel-guide {
  position: relative;
  height: 1000px;
  overflow-y: auto;

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .card {
    width: 488px;
    margin-bottom: 48px;
    margin: 24.5px;
  }

  .not-place {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 56px;
    text-transform: uppercase;
  }
}

.tags {
  position: absolute;
  bottom: 176px;
  left: 0px;
  width: 100%;
  background-color: #1e1e1e;
  border-top: 3px solid rgba(255, 255, 255, 0.16);
}

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 40px;
}
</style>
